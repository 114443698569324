<template>
    <a-card title="身份认证" :bordered="false">
        <a-form-model class="form-model" layout="vertical" ref="form" :model="formField" :rules="formRules">
            <a-form-model-item label="姓名(必须与身份证姓名一致)：" prop="username">
                <a-input
                    style="width: 80%;max-width: 300px"
                    placeholder="请填写您的姓名"
                    v-model="formField.username"
                />
            </a-form-model-item>
            <a-form-model-item label="身份证号码：" prop="idcard">
                <a-input
                    style="width: 80%;max-width: 300px"
                    placeholder="请填写您的身份证号码"
                    v-model="formField.idcard"
                />
            </a-form-model-item>
            <a-form-model-item label="身份证有效期 (长期请选择结束日期为2099-12-31)：" prop="idcard_expiry" help="">
                <a-range-picker style="width: 80%;max-width: 300px" v-model:value="formField.idcard_expiry" />
            </a-form-model-item>
            <a-form-model-item label="身份证正面照片：" prop="idcard_img_front">
                <image-upload :max-size="300" v-model="formField.idcard_img_front">
                    <template v-slot:extra>
                        <p>1、带国徽的那一面</p>
                        <p>
                            2、图片大小需小于300K<a-popover placement="bottom">
                                <template slot="content">
                                    <img
                                        style="width: 200px"
                                        src="~@/assets/images/idcard-front.png"
                                        alt="身份证正面"
                                    />
                                </template>
                                <a-button style="font-size:14px;padding: 0 5px" type="link">示例</a-button>
                            </a-popover>
                        </p>
                    </template>
                </image-upload>
            </a-form-model-item>
            <a-form-model-item label="身份证反面照片：" prop="idcard_img_reverse">
                <image-upload :max-size="300" v-model="formField.idcard_img_reverse">
                    <template v-slot:extra>
                        <p>1、带个人信息的那一面</p>
                        <p>
                            2、图片大小需小于300K<a-popover placement="bottom">
                                <template slot="content">
                                    <img
                                        style="width: 200px"
                                        src="~@/assets/images/idcard-reverse.png"
                                        alt="身份证反面"
                                    />
                                </template>
                                <a-button style="font-size:14px;padding: 0 5px" type="link">示例</a-button>
                            </a-popover>
                        </p>
                    </template>
                </image-upload>
            </a-form-model-item>
            <a-form-model-item label="收款银行：" prop="bank">
                <a-select show-search style="width: 80%;max-width: 300px" v-model="formField.bank">
                    <a-select-option value="">请选择收款银行</a-select-option>
                    <a-select-option :value="name" v-for="(name, index) in bankArr" :key="index">
                        {{ name }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="开户行：" prop="bank_area">
                <a-input
                    style="width: 80%;max-width: 300px"
                    placeholder="例：建设银行厦门集美支行"
                    v-model="formField.bank_area"
                />
            </a-form-model-item>
            <a-form-model-item label="银行账号：" prop="bank_cart">
                <a-input
                    style="width: 80%;max-width: 300px"
                    placeholder="请填写银行账号"
                    v-model="formField.bank_cart"
                />
            </a-form-model-item>
            <a-form-model-item label=" " :colon="false">
                <a-button size="large" type="primary" :loading="loadBtn" :disabled="loadBtn" @click="formSubmit">
                    保存
                </a-button>
            </a-form-model-item>
        </a-form-model>
        <a-modal width="80%" :title="null" :footer="null" :closable="false" :visible="!privacyProtocol.isAgree">
            <div class="protocol-text">
                <protocol :type="1" />
            </div>
            <a-button
                class="protocol-btn"
                size="large"
                type="primary"
                :disabled="privacyProtocol.countDown > 0"
                @click="privacyProtocolAgree"
                >我已认真阅读并认可本协议{{
                    privacyProtocol.countDown > 0 ? `(${privacyProtocol.countDown})` : ""
                }}</a-button
            >
        </a-modal>
        <a-modal width="80%" :title="null" :footer="null" :closable="false" :visible="!shareProtocol.isAgree">
            <div class="protocol-text">
                <protocol type="2" />
            </div>
            <a-button
                class="protocol-btn"
                size="large"
                type="primary"
                :disabled="shareProtocol.countDown > 0"
                @click="shareProtocolAgree"
                >我已认真阅读并认可本协议{{
                    shareProtocol.countDown > 0 ? `(${shareProtocol.countDown})` : ""
                }}</a-button
            >
        </a-modal>
    </a-card>
</template>

<script>
import imageUpload from "@/components/imageUpload";
import protocol from "@/components/protocol";
import { mapState } from "vuex";
import moment from "moment";
export default {
    components: {
        imageUpload,
        protocol,
    },
    computed: {
        ...mapState({
            userInfo: (state) => state.user.user_info,
        }),
    },
    data() {
        return {
            bankArr: [
                "北京银行",
                "广发银行股份有限公司",
                "华夏银行",
                "交通银行",
                "宁波银行",
                "平安银行（原深圳发展银行）",
                "上海浦东发展银行",
                "上海银行",
                "兴业银行",
                "中国工商银行",
                "中国光大银行",
                "中国建设银行",
                "中国民生银行",
                "中国农业银行",
                "中国银行",
                "中国邮政储蓄银行",
                "中国招商银行",
                "中信银行",
                "福建省农村信用社联合社",
            ],
            formRules: {
                username: [{ required: true, message: "请填写您的姓名", trigger: "blur" }],
                idcard: [{ required: true, validator: this.idcardCheck, trigger: "change" }],
                idcard_img_front: [{ required: true, message: "请上传身份证正面照片" }],
                idcard_img_reverse: [{ required: true, message: "请上传身份证反面照片" }],
                idcard_expiry: [{ required: true, validator: this.idcardExpiryCheck, trigger: "change" }],
                bank: [{ required: true, message: "请选择收款银行" }],
                bank_area: [{ required: true, message: "请填写开户行信息" }],
                bank_cart: [
                    { required: true, message: "请填写银行卡号" },
                    { pattern: /^\d{6,32}$/, message: "请检查卡号是否正确" },
                ],
            },
            formField: {
                username: "",
                idcard: "",
                idcard_img_front: "",
                idcard_img_reverse: "",
                idcard_expiry: [],
                bank: "",
                bank_area: "",
                bank_cart: "",
            },
            // 用户隐私协议弹窗
            privacyProtocol: {
                isAgree: true,
                countDown: 9,
                countDownTimer: "",
            },
            // 共享合作协议
            shareProtocol: {
                isAgree: true,
                countDown: 9,
                countDownTimer: "",
            },
            loadBtn: false,
        };
    },
    watch: {
        userInfo() {
            this.updateUserinfo();
        },
    },
    mounted() {
        this.updateUserinfo();
    },
    methods: {
        // 身份证年满16周岁验证
        idcardCheck(rule, value, callback) {
            let val = value.replace(/(^\s*)|(\s*$)/g, "");
            if (!val) {
                callback(new Error("请填写您的身份证号"));
            }
            // 正则匹配
            if (
                !/(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$)/.test(
                    val
                )
            ) {
                callback(new Error("请填写正确的身份证号"));
            }
            let birthdayMoment = moment(
                val.slice(6, 10) + "/" + val.slice(10, 12) + "/" + val.slice(12, 14),
                "YYYY/MM/DD"
            );
            let minBirthdayMoment = moment().add("-16", "y");
            if (minBirthdayMoment.isSameOrBefore(birthdayMoment)) {
                callback(new Error("根据相关法律规定，未满16岁的身份信息不可签约"));
            }
            callback();
        },
        // 身份证有效期校验
        idcardExpiryCheck(rule, value, callback) {
            if (value.length < 2) {
                callback(new Error("请填写身份证有效期"));
            }
            // 最小有效期5年
            let startMoment = moment(value[0]);
            if (!startMoment.add("4", "y").isBefore(value[1], "year")) {
                callback(new Error("请填写正确身份证有效期"));
            }
            // 截止日期：不可以早于当前日期，否则就是过期的身份证
            if (value[1].isSameOrBefore(moment())) {
                callback(new Error("身份证有效截止日期不可以早于当前日期"));
            }
            callback();
        },
        // 更新用户信息
        updateUserinfo() {
            let userInfo = this.$store.state.user.user_info;
            if (Object.prototype.toString.call(userInfo.auth_info) === "[object Object]") {
                // 兼容新增字段
                let idcardExpiry = [];
                if (userInfo.auth_info.idcard_expiry_start) {
                    idcardExpiry = [
                        moment(userInfo.auth_info.idcard_expiry_start),
                        moment(userInfo.auth_info.idcard_expiry_end),
                    ];
                }
                Object.assign(this.formField, {
                    username: userInfo.auth_info.username,
                    idcard: userInfo.auth_info.idcard,
                    idcard_img_front: userInfo.auth_info.idcard_img_front,
                    idcard_img_reverse: userInfo.auth_info.idcard_img_reverse,
                    idcard_expiry: idcardExpiry,
                });
            }
            if (Object.prototype.toString.call(userInfo.collection_info) === "[object Object]") {
                Object.assign(this.formField, {
                    bank: userInfo.collection_info.reviewed.bank,
                    bank_area: userInfo.collection_info.reviewed.bank_area,
                    bank_cart: userInfo.collection_info.reviewed.bank_cart,
                });
            }
            // 未同意协议
            if (userInfo.is_contract == 0) {
                this.privacyProtocol.isAgree = false;
                this.privacyProtocol.countDown = 9;
                this.privacyProtocol.countDownTimer = setInterval(() => {
                    this.privacyProtocol.countDown -= 1;
                    if (this.privacyProtocol.countDown <= 0) {
                        clearInterval(this.privacyProtocol.countDownTimer);
                    }
                }, 1000);
            }
        },
        // 同意用户隐私协议
        privacyProtocolAgree() {
            this.privacyProtocol.isAgree = true;
            this.$api.authContract();
        },
        // 同意共享合作协议
        shareProtocolAgree() {
            this.shareProtocol.isAgree = true;
            this.loadBtn = true;
            this.formSubmitHaldle();
        },
        // 表单提交
        formSubmit() {
            this.$refs.form.validate((ispass) => {
                if (ispass) {
                    // 弹出共享补充协议
                    this.shareProtocol.isAgree = false;
                    this.shareProtocol.countDown = 9;
                    this.shareProtocol.countDownTimer = setInterval(() => {
                        this.shareProtocol.countDown -= 1;
                        if (this.shareProtocol.countDown <= 0) {
                            clearInterval(this.shareProtocol.countDownTimer);
                        }
                    }, 1000);
                }
            });
        },
        // 表单提交处理
        formSubmitHaldle() {
            let ajaxResArr = [];
            // 身份数据
            this.$api
                .updateAuthInfo({
                    extend: {
                        username: this.formField.username,
                        idcard: this.formField.idcard,
                        idcard_img_front: this.formField.idcard_img_front,
                        idcard_img_reverse: this.formField.idcard_img_reverse,
                        jxt_agree: 1, // 同意合作协议
                        idcard_expiry_start: this.formField.idcard_expiry[0].format("YYYY-MM-DD"),
                        idcard_expiry_end: this.formField.idcard_expiry[1].format("YYYY-MM-DD"),
                        bank: this.formField.bank,
                        bank_area: this.formField.bank_area,
                        bank_cart: this.formField.bank_cart,
                    },
                })
                .then((res) => {
                    this.loadBtn = false;
                    if(res.status) {
                        this.$message.success({ content: "更新成功，请等待审核", key: "message" });
                        setTimeout(() => {
                            this.$store.dispatch("user/getUserinfo");
                            this.$router.push({ name: "myInfo" });
                        }, 1500);
                    } else {
                        this.$message.error({ content: res.msg, key: "message" });
                    }
                });
        }
    },
};
</script>

<style lang="less" scoped>
.protocol-text {
    max-height: 70vh;
    overflow-y: scroll;
}
.protocol-btn {
    margin: 20px auto 0;
    display: block;
}
</style>
