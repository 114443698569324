<template>
    <div>
        <template v-if="type == 1">
            <h1>用户服务协议与隐私条款</h1>
            <p>
                本协议是您与厦门天象文化有限公司（以下简称“天象公司”）之间就天象创作者平台网站服务等相关事宜所订立的契约，请您仔细阅读本用户服务协议与隐私条款，阅读完毕后，选择“我已阅读并且同意”，进行实名认证，本协议即构成对双方有约束力的法律文件，如您不同意相关条约，请立即停止使用本平台服务。
            </p>
            <h3>第一条 用户信息</h3>
            <h3>第二条 天象创作者平台可能收集哪些信息</h3>
            <h3>第三条 信息使用</h3>
            <h3>第四条 信息披露</h3>
            <h3>第五条 信息存储和交换</h3>
            <h3>第六条 Cookie的使用</h3>
            <h3>第七条 信息安全</h3>
            <h3>第八条 用户依法言行义务</h3>
            <h3>第九条 所有权及知识产权条款</h3>
            <h3>第十条 责任限制及不承诺担保</h3>
            <h3>第十一条 协议更新及用户关注义务</h3>
            <h3>第十二条 法律管辖和适用</h3>
            <h3>第十三条 其他</h3>
            <p>
                本协议由天象公司的天象创作者平台随时更新，更新后的协议条款一旦公布即代替原来的协议条款，您可在天象创作者平台查阅最新版协议条款，恕不再另行通知。在修改协议条款后，如果您不接受修改后的条款，请立即停止使用天象创作者平台提供的服务，您继续使用服务将被视为接受修改后的协议。以下为本协议的具体内容：
            </p>
            <h2>第一条 用户信息</h2>
            <p>
                1.用户应自行诚信向天象创作者平台提供注册资料，用户同意其提供的注册资料真实、准确、完整、合法有效，用户注册资料如有变动的，应及时更新其注册资料。如果用户提供的注册资料不合法、不真实、不准确、不详尽的，用户需承担因此引起的相应责任及后果，并且天象创作者平台保留终止用户使用天象创作者平台各项服务的权利。
            </p>
            <p>
                2.用户同意，天象创作者平台有权使用用户的注册信息、用户名、密码等信息，登录进入用户的注册账户，进行证据保全，包括但不限于公证、见证等。
            </p>

            <h2>第二条 天象创作者平台可能收集哪些信息</h2>

            <p>1.在用户注册天象创作者平台帐号时，用户根据天象创作者平台的要求提供的个人注册信息。</p>

            <p>2.用户在天象创作者平台进行登陆、投稿直至结算时，涉及用户真实姓名/名称、通信地址、联系电话、电子邮箱、身份证信息、银行卡等隐私信息的。</p>

            <p>3.用户的账号及密码。</p>

            <p>4.在用户使用天象创作者平台的网络服务，或访问平台时，自动接收并记录的用户的浏览器和计算机上的信息，包括但不限于用户的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及用户需求的网页记录等数据等。</p>

            <h2>第三条 信息使用</h2>

            <p>1.天象创作者平台可能会通过邮件、短信电话等形式，向在天象创作者平台注册、投稿用户发送登陆动态密码或其它相关信息。</p>

            <p>2.天象创作者平台不会向任何无关第三方提供、出售、出租、分享或交易用户的个人信息，除非事先得到用户的许可，或该第三方和天象创作者平台（含关联公司）单独或共同为用户提供服务。</p>

            <p>3.在不透露单个用户隐私资料的前提下，天象创作者平台有权对整个用户数据库进行分析并对用户数据库进行商业上的利用。</p>

            <p>4.为提高用户的使用感受和满意度，天象创作者平台可能通过使用用户的个人信息，向用户提供更加个性化的服务，包括但不限于向用户发出推送相关度更高的搜索结果或用户更感兴趣的资讯，或者与合作伙伴共享信息以便他们向用户发送有关其产品和服务的信息（后者需要用户的事先同意）。</p>

            <h2>第四条 信息披露</h2>

            <p>在如下情况下，天象创作者平台将依据用户的个人意愿或法律的规定全部或部分的披露用户的个人信息：</p>

            <p>1.经用户事先同意，向第三方披露；</p>

            <p>2.天象创作者平台可能会与第三方合作，共同向用户提供相关的网络服务。在此情况下，如该第三方同意承担与天象创作者平台同等的保护用户隐私的责任，则天象创作者平台有权将用户的必要信息提供给该第三方，并无须另行告知用户；</p>

            <p>3.根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；</p>

            <p>4.如用户出现违反中国有关法律、法规或者天象创作者平台的服务协议或相关规则的情况，需要向第三方披露；</p>

            <p>5.其它根据法律、法规或者网站政策认为合适的披露。</p>

            <h2>第五条 信息存储和交换</h2>

            <p>天象创作者平台收集的有关用户的信息和资料将保存在厦门天象文化有限公司及（或）其关联公司的服务器上，这些信息和资料可能传送至用户所在国家、地区或天象创作者平台收集信息和资料所在地的境外并在境外被访问、存储和展示。</p>

            <h2>第六条 Cookie的使用</h2>

            <p>1.在用户未拒绝接受cookies的情况下，天象创作者平台会在用户的计算机上设定或取用cookies，以便用户能登录或使用依赖于cookies的平台服务或功能。天象创作者平台使用cookies可为用户提供更加周到的个性化服务，包括推广服务。</p>

            <p>2.用户有权选择接受或拒绝接受cookies。用户可以通过修改浏览器设置的方式拒绝接受cookies。但如果用户选择拒绝接受cookies，则用户可能无法登录或使用依赖于cookies的网络服务或功能。</p>

            <p>3.通过天象创作者平台所设cookies所取得的有关信息，将适用本政策。</p>

            <h2>第七条 信息安全</h2>

            <p>1.天象创作者平台的帐号均有安全保护功能，请妥善保管用户的用户名及密码信息。天象创作者平台将通过对用户密码进行加密等安全措施确保用户的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请用户注意在信息网络上不存在“完善的安全措施”。</p>

            <p>
                2.用户有义务保证密码和帐号的安全，请勿将用户的账号、密码转让或出借予以他人使用。用户利用该密码和帐号所进行的一切活动引起的任何损失、损害、信息丢失、隐私泄露，由用户自行承担全部责任，天象创作者平台不承担任何责任。如用户发现帐号遭到未授权的使用或发生其他任何安全问题，应立即修改帐号密码并妥善保管，如有必要，请反馈通知天象创作者平台管理人员。因黑客行为或用户的保管疏忽导致帐号非法使用，天象创作者平台不承担任何责任。
            </p>

            <h2>第八条 用户依法言行义务</h2>

            <p>本协议依据国家相关法律法规规章制定，用户同意严格遵守以下义务：</p>

            <p>1.不得传输或发表：煽动抗拒、破坏宪法和法律、行政法规实施的言论，煽动颠覆国家政权，推翻社会主义制度的言论，煽动分裂国家、破坏国家统一的的言论，煽动民族仇恨、民族歧视、破坏民族团结的言论；</p>

            <p>2.从中国大陆向境外传输资料信息时必须符合中国有关法规；</p>

            <p>3.不得利用天象创作者平台从事洗钱、窃取商业秘密、窃取个人信息等违法犯罪活动。</p>

            <h2>第九条 所有权及知识产权条款</h2>

            <p>
                1.用户一旦接受本协议，即表明该用户主动将其在任何时间段在天象创作者平台发表的任何形式的信息内容的财产性权利等任何可转让的权利，如投稿的文章的著作权财产权（包括并不限于：复制权、发表权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权以及应当由著作权人享有的其他可转让权利），全部独家且不可撤销地转让给天象公司的天象创作者平台所有，用户同意天象创作者平台有权就任何主体侵权而单独提起诉讼。
            </p>

            <p>2.本协议已经构成《中华人民共和国著作权法》第二十五条（条文序号依照2011年版著作权法确定）及相关法律规定的著作财产权等权利转让书面协议，其效力及于用户在网站上发布的任何受著作权法保护的作品内容，无论该等内容形成于本协议订立前还是本协议订立后。</p>

            <p>3.用户同意并已充分了解本协议的条款，承诺不将已发表于天象创作者平台的信息，以任何形式发布或授权其它主体以任何方式使用（包括但不仅限于在各类网站、媒体上使用）。</p>

            <p>4.天象公司是天象创作者平台制作者，拥有此网站内容及资源的著作权等合法权利，受国家法律保护，有权不时地对本协议及天象创作者平台的内容进行修改，并在网站张贴，或通过平台信息功能通知用户。在法律允许的最大限度范围内，天象创作者平台对本协议及平台内容拥有解释权。</p>

            <p>5.除法律另有强制性规定外，未经天象创作者平台明确的特别书面许可，任何单位或个人不得以任何方式非法地全部或部分复制、转载、引用、链接、抓取或以其他方式使用平台信息内容，否则，天象创作者平台有权追究其法律责任。</p>

            <p>
                6.平台所刊登的资料信息（诸如文字、图表、标识、按钮图标、图像、声音文件片段、数字下载、数据编辑和软件），均是天象公司及天象创作者平台或其内容提供者的财产，受中国和国际版权法的保护。平台上所有内容的汇编是天象创作者平台的排他财产，受中国和国际版权法的保护。平台所有信息都是天象创作者平台或其关联公司或其用户的财产，受中国和国际版权法的保护。
            </p>

            <h2>第十条 责任限制及不承诺担保</h2>

            <p>
                除非另有明确的书面说明，天象公司及天象创作者平台及其所包含的或以其它方式通过天象公司及天象创作者平台提供给用户的全部信息、内容、材料、和服务，均是在"按现状"和"按现有"的基础上提供的。
                除非另有明确的书面说明，天象创作者平台不对天象公司及天象创作者平台的运营及其包含在本网站上的信息、内容、材料或服务作任何形式的、明示或默示的声明或担保（根据中华人民共和国法律另有规定的以外）。
                天象创作者平台不担保天象公司及天象创作者平台所包含的或以其它方式通过天象公司及天象创作者平台提供给用户的全部信息、内容、材料和服务、其服务器或从天象公司及天象创作者平台发出的电子信件、信息没有病毒或其他有害成分。
                如因不可抗力或其它天象公司及天象创作者平台无法控制的原因使天象公司及天象创作者平台系统崩溃或无法正常使用导致网上交易无法完成或丢失有关的信息、记录等，天象创作者平台会合理地尽力协助处理善后事宜。拥有此平台内容及资源的著作权等合法权利，受国家法律保护，有权不时地对本协议及天象创作者平台天象公司及天象创作者平台的内容进行修改，并在天象公司及天象创作者平台张贴，无须另行通知用户。在法律允许的最大限度范围内，天象创作者平台对本协议及天象公司及天象创作者平台内容拥有解释权。
            </p>

            <h2>第十一条 协议更新及用户关注义务</h2>

            <p>
                根据国家法律法规变化及网站运营需要，天象创作者平台有权对本协议条款不时地进行修改，修改后的协议一旦被张贴在天象公司及天象创作者平台上即生效，并代替原来的协议。用户可随时登录查阅最新协议；用户有义务不时关注并阅读最新版的协议及网站公告。如用户不同意更新后的协议，可以且应立即停止接受网站依据本协议提供的服务；如用户继续使用本网站提供的服务的，即视为同意更新后的协议。天象创作者平台建议用户在使用天象公司及天象创作者平台之前阅读本协议及天象公司及天象创作者平台的公告。
                如果本协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。
            </p>

            <h2>第十二条 法律管辖和适用</h2>

            <p>
                本协议的订立、执行和解释及争议的解决均应适用在中华人民共和国大陆地区适用之有效法律（但不包括其冲突法规则）。如发生本协议与适用之法律相抵触时，则这些条款将完全按法律规定重新解释，而其它有效条款继续有效。如缔约方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，任何一方均可向有管辖权的中华人民共和国大陆地区法院提起诉讼。
            </p>

            <h2>第十三条 其他</h2>

            <p>1.网站所有者是指在政府部门依法许可或备案的网站经营主体。</p>

            <p>2.天象创作者平台尊重用户和作者的合法权利，本协议及本网站上发布的各类规则、声明等其他内容，均是为了更好的、更加便利的为用户和消费者提供服务。天象公司及天象创作者平台欢迎用户和社会各界提出意见和建议，天象创作者平台将虚心接受并适时修改本协议及天象公司及天象创作者平台上的各类规则。</p>

            <p>3.用户仔细阅读本注册协议，阅读完毕后，选择注册页面中的“我已阅读并且同意”，继续完成认证信息，即视为用户完全接受本协议，在点击之前请用户再次确认已知悉并完全理解本协议的全部内容。</p>
        </template>
        <template v-else>
            <h1>共享经济合作伙伴协议</h1>
            <p>《共享经济合作伙伴协议》（下称“本协议”）为 公 司（以下简称“本公司”）与您（即本协议项下的自由职业者）签订的合作协议。如果您不同意本协议的任意内容，请不要勾选或以其他方式进行后续操作；您点击勾选或以其他方式确认本 协议，即表示您已充分阅读、理解并同意接受本协议的全部内容。</p>
            <p>感谢您选择本公司为您提供共享经济综合服务，本公司将依托综合实力竭诚为您提供规范、 专业的服务。</p>
            <p>特别提示：</p>
            <p>
                您是为您正在注册使用的网站、APP、小程序运营方（含关联方）（以下称“企业客户”）提供服务的从事生产经营活动的自由职业者。本公司为您提供共享经济综合服务。    您与企业客户系适用《中华人民共和国合同法》约束的平等主体之间的合作关系，您不是企业客户的法定代表人、股东、董事、监事等，与企业客户之间不具有劳动/劳务合同关系或其他类似的劳动人事法律关系；且不属于国家法律法规和纪律规定禁止从事兼职或经商的人员。
            </p>
            <p>如您不符合上述规定，请不要勾选同意本协议内容；违反上述规定行为的，本公司有权上报本公司主管税务机关或/及其他相关国家机关，由本公司主管税务机关或/及其他相关国家机关根据《中华人民共和国税收征收管理法》及其他相关法律法规的规定追究责任主体的法律责任。</p>
            <p>鉴于：</p>
            <p>本公司具备共享经济资源平台及智能系统，可为诸多互联网平台企业提供共享经济综合服务；您系本公司为企业客户筛选的自由职业者，能满足企业客户的商业需求。您与本公司将依据《中华人民共和国合同法》及相关法律法规的规定，本着平等、自愿、诚信的原则,在平等互利的基础上签署本协议。</p>
            <h2>第一条 定义</h2>
            <p>除非本协议中另有定义，下列词语应具有以下特定涵义：</p>
            <p>
                <b>1、关联方：</b>指相互间直接或间接持有其中一方的股权总和达到 25%或以上的；或者直接或 间接同为第三者所拥有或控制股权达到 25%或以上的。如果一方通过中间方对另一方间接持有 股份，只要其对中间方持股比例达到 25%以上，则其对另一方的持股比例按照中间方对另一方
                的持股比例计算；两个以上具有夫妻、直系血亲、兄弟姐妹以及其他抚养、赡养关系的自然人 共同持股同一企业，在判定关联关系时持股比例合并计算。
            </p>
            <p>
                <b>2、自由职业者：</b
                >指本公司为企业客户筛选的并向企业客户提供合法合规生产经营的个人。该个人指依靠自身的经验或技能，自愿独立承揽相关服务项目，以个体身份为服务需求方提供服务，对所服务的具体内容和服务时间享有自主选择、自由支配权，并对所从事的服务或生产经营活动独立自愿承担风险和责任的，具有完全民事行为能力和责任能力的自然人。
            </p>
            <p><b>3、代征：</b>指本公司或本公司委托具有相关资质的服务方根据《委托代征协议书》的有关规定，接受相关主管税务机关的委托， 以相关主管税务机关的名义向从事生产经营的自由职业者征收应缴纳的个人所得税的行为。</p>
            <p><b>4、生产经营收入（税后）：</b>指您提供生产经营活动而取得的税后收入。</p>
            <p><b>5、服务费：</b>指由本公司向企业客户提供共享经济综合服务，本公司就该等服务向企业客户收取的费用。</p>
            <p><b>6、身份标识：</b>您为中国公民且持有有效《中华人民共和国居民身份证》的，为居民身份证（简称“身 份证”)；</p>
            <h2>第二条 合作内容</h2>
            <p>1、本公司依据与企业客户签署的《共享经济灵活用工平台综合服务协议》的约定，依托共享经济资源向企业客户筛选并推荐您为企业客户提供相应生产经营活动并按照企业客户业务规则查询、接受活动需求、履行生产经营活动过程中的相关义务并享有相关权利等。</p>
            <p>2、您委托本公司或本公司的服务方按照有关税收法律法规、政策规定及相关主管税务机关的要求为您代征个人所得税税款。</p>
            <p>3、本公司服务的对象仅限于从事合法合规生产经营的个人。</p>
            <h2>第三条 生产经营收入（税后）的结算及支付方式</h2>
            <p>
                1、根据本公司与企业客户签署的《共享经济灵活用工平台综合服务协议》的约定，本公司有权向企业客户就所提供的共享经济综合服务收取服务费，其中，服务费金额涵盖您基于生产经营活动而取得的收入。本公司收到企业客户支付的服务费后，将依据企业客户提供的您所提供服务的数量、
                质效标准及考核结果核算您的生产经营收入（税后）并支付予您。
            </p>
            <p>2、您应自行负担就您取得的生产经营收入需缴纳的各项税款，应本公司要求，您应提供与个人所得税申报缴纳相关的协助及信息。如果您拒绝提供相关协助或信息，本公司有权暂缓支付生产经营收入（税后）。</p>
            <h2>第四条 您的权利与义务</h2>
            <p>1、您保证签署本协议时已满十八周岁并具有完全民事权利能力和民事行为能力，同时满足所有履行本协议所需的法定条件或约定条件，保证 为企业客户提供生产经营活动时，遵照其业务规则并自备所需要的设备设施、自行承担必要费用，如因您个人原因造成您自己或任意第三方损害的，由您承担全部法律责任。</p>
            <p>2、您保证为企业客户提供的生产经营活动是以您个人名义进行的，与您名下的个体工商户 （如有）没有任何关系。</p>
            <p>
                3、您保证在生产经营活动过程中遵守国家法律法规，不得从事违反法律或行政法规等行为 （包括但不限于涉嫌洗钱、偷税、漏税、逃税）及其他不得使用本公司服务的行为（包括但不 限于您为企业客户提供活动的特点、性质不符合本协议第二条约定的本公司服务范围的情形），
                本公司/企业客户主管税务机关或/及其他相关国家机关介入调查时，您应当予以协助并如实反映 情况，不得阻挠、拒绝调查。
            </p>
            <p>4、您充分理解自由职业者的定义，并承诺不存在下列所述情形：</p>
            <p>4.1 与企业客户具有劳动/劳务合同关系或其他类似的劳动人事法律关系；</p>
            <p>4.2 为企业客户的法定代表人、股东、董事、监事；</p>
            <p>4.3 其他不适用于本协议第二条第 3 款本公司服务范围之规定的人员。</p>
            <p>
                您保证向本公司提供的信息均是真实的、有效的、合法的，如信息发生变更（如不再是自
                由职业者身份）应及时通知本公司。您的账户信息以您实名提交至企业客户平台后台的信息为准，且您保证该账户为纳税义务人所有。自本公司或本公司委托的服务方将应支付的生产经营收入（税后）支付至您的账户之日起，视为您收到前述费用。如因您提供信息有误等导致付款失败的，您独自承担相
                应责任。您承诺您所提供的账户应符合中华人民共和国法律的规定，如果本公司或本公司委托的服务方收到相关主管税务机关或/及其他相关国家机关任何调查请求的，您应提供协助并如实反映情况。
            </p>
            <p>5、您于本平台处注册时所使用的唯一身份标识仅限本人使用，因您泄露或提供给他人使用该等身份标识而造成的不利后果，由您自行承担。</p>
            <p>
                6、您同意本公司、本公司委托的服务方或/及企业客户为实现本协议合作之目的收集您的个人信息（包括但不限于 您的姓名、身份证号、收款账户信息、接单数量及费用）。如本公司或/及企业客户收集、存储的您的个人信息有错误，您有权要求予以更正；如本公司或/及企业客户违反法律、行政法规的
                规定或者双方的约定收集、使用您的个人信息，您有权要求予以删除。
            </p>
            <h2>第五条 本公司的权利与义务</h2>
            <p>1、本公司有权根据企业客户的业务需求以及企业客户安排的服务内容，就您提供的生产经营活动进行监督，双方为平等主体，适用 《中华人民共和国合同法》约束。本公司为使您满足企业客户业务需求而向您提供的服务，不导致本公司与您之间构成劳动/劳务关系，且本公司对您违反法律法规的行为不承担法律责任。</p>
            <p>2、本公司或本公司委托的服务方在向您支付生产经营收入（税后）时，有权在本公司或本公司委托的服务方所在区，按照有关税收政策规定及主管税务机关的要求，向您代征个人所得税税款。（您授权同意本公司或本公司委托的服务方进行报税的相关登记。）</p>
            <p>3、本公司发现您违反本协议第四条第 4 款之约定的，有权立即中止履行本协议，并向相关主管税务机关报告，您应自行承担由此产生的税务相关的处罚责任（包括但不限于根据本公司主管税务机关的要求补缴相应的税款、滞纳金、罚金）。</p>
            <p>
                4、本公司可以留存为实现本次合作内容从企业客户/您处获取的您的个人信息，本公司（含
                股东会、董事会、监事会成员及高级管理人员）承诺对您所披露的个人隐私信息进行保密，未经您授权或基于合法并可执行的法律、法规、法院命令、监管机构的要求或为遵守适用法律法规、履行网络安全保护义务或应对网络安全事件的需要，本公司（含股东会、董事会、监事会成员及高级管理人员）不得将您的个人信息披露给任意第三方，如本公司违反此约定造成您相应经济损失的，本公司应承担该损失的全部赔偿责任。本公司承诺按照相关法律法规的要求对您的个人信息履行安全保护义务，保障网络免受干扰、破坏或未经授权的访问，防止网络数据泄露或被窃取、篡改。
            </p>
            <p>5、本公司因网络服务的特殊性需短时间中断服务对系统进行维护和升级，您理解并认可前述行为不属于违约情形。</p>
            <p>6、如企业客户与您之间另有服务或其他约定安排的，本公司不就未参与的安排承担任何义务。</p>
            <h2>第六条 违约责任</h2>
            <p>1、除本协议另有约定外，本协议任意一方不履行或不完全履行本协议约定的义务和承诺即构成违约。违约方应当负责赔偿其违约行为给守约方造成的一切经济损失（包括但不限于合理的律师费、诉讼费、公证费）。</p>
            <p>2、因政府行为（包括但不限于因中国政府机关不授予或调整本公司或本公司委托的服务方相应经营资质或权利） 或其他不可抗力因素导致本协议部分条款或全部条款无法履行的，遭受该行为的一方不承担违约责任。</p>
            <h2>第七条 保密责任</h2>
            <p>1、本协议任意一方应对本协议所涉及的所有内容以及协议双方在执行本协议过程中相关的 法律、商业、合作业务的所有信息进行保密。未经对方允许，均不得向任意第三方披露。</p>
            <p>2、保密期限应为：自协议生效之日起至协议终止后十年。</p>
            <h2>第八条 适用法律、争议及纠纷解决和司法管辖</h2>
            <p>本协议的订立、执行、解释和争议的解决均应适用中华人民共和国法律。凡因本协议引起 的或与本协议有关的任何争议，协议双方应友好协商解决。如不能协商解决，双方一致同意提交至被告所在地有管辖权的人民法院诉讼解决。</p>
            <h2>第九条 通知</h2>
            <p>除本协议另有约定外，在履行本协议过程中的一切通知，本公司可通过网页公告、电子邮 件、系统通知、短信提醒、电话或其他合理方式向您送达，该等通知于发送之日视为已送达收件人。</p>
            <h2>第十条 协议的变更与终止</h2>
            <p>1、本公司有权根据需要不时地更新本协议条款，并在企业客户 APP、小程序或以其他方式予以公布。如您同意更新版本，可点击或/及勾选“同意”或/及“确认”继续使用本公司服务；如您不同意所修改的内容，请立即停止使用本公司服务，并可自行注销账户信息。</p>
            <p>2、您应遵守本协议约定及相关法律法规的规定，本公司有权自主判断您的行为是否违约或及违规。如本公司有合理理由或有证据认定您违反本协议或相关法律法规的，有权在不事先通知的情况下，限制、停止或取消您使用本公司服务的资格。</p>
        </template>
    </div>
</template>
<script>
export default {
    props: {
        // 类型：隐私协议1，共享协议2
        type: {
            type: String,
            default: 1,
        },
    },
};
</script>
<style lang="less">
h2 {
    margin: 10px 0 4px;
    font-size: 20px;
    font-weight: 600;
}
h3 {
    margin: 10px 0 4px;
    font-size: 18px;
}
p {
    margin-top: 10px;
    font-size: 16px;
    margin-bottom: 0;
}
em {
    text-decoration: underline;
}
</style>
