<template>
	<div class="image-upload">
		<a-upload
			v-if="!imgUrl"
			accept="image/jpg,image/jpeg,image/png,image/gif"
			:show-upload-list="false"
			:before-upload="beforeUpload"
			:custom-request="actionUpload"
			:disabled="loading"
		>
			<div class="image-wrap">
				<div class="image-before">
					<img class="image-before__img" src="~@/assets/images/logo-ash.png" alt="天象" />
					<p class="image-before__text"><a-icon :type="loading ? 'loading' : 'plus'" />{{ loading ? "上传中" : "上传图片" }}</p>
				</div>
			</div>
		</a-upload>
		<div v-else class="image-wrap">
			<div class="image-after">
				<img class="image-after__img" :src="imgUrl" />
				<div v-if="loading" class="image-after__loading">
					<a-icon type="loading" />
				</div>
				<div v-if="!loading && !disabled" class="image-after__mask">
					<a-upload
						accept="image/jpg,image/jpeg,image/png,image/gif"
						:show-upload-list="false"
						:before-upload="beforeUpload"
						:custom-request="actionUpload"
						:disabled="loading"
					>
						<a-button class="mask-btn" type="primary">换一张</a-button>
					</a-upload>
				</div>
			</div>
		</div>
		<div class="image-extra">
			<slot name="extra"></slot>
		</div>
	</div>
</template>
<script>
import {img2base64} from "@/common/media";
export default {
	props: {
		// 默认图片地址
		value: {
			type: String
		},
		// 上传图片大小限制, kb
		maxSize: {
			type: Number,
			default: 2048
		},
		disabled: {
			type: [String, Boolean],
			default: false
		}
	},
	watch: {
		value(val) {
			this.imgUrl = val || '';
		}
	},
	data() {
		return {
			loading: false,
			imgUrl: this.value || '',
		};
	},
	methods: {
		// 上传前拦截,限制大小
		beforeUpload(file) {
			if (file.size >= (this.maxSize * 1024)) {
				let maxSizeVal = parseInt(this.maxSize / 1024);
				let tipTxt = maxSizeVal > 0 ? (maxSizeVal + 'M') : (this.maxSize + 'K');
				this.$message.error(`请上传小于${tipTxt}的图片`);
				return false;
			}
		},
		// 上传文件
		actionUpload(upload) {
			let imgFile = upload.file;
			img2base64(imgFile).then((image) => {
				// 上传base64
				this.loading = true;
				this.$api
					.imageBase64({
						especially: "d1xz",
						data: image.code,
						suffix: image.ext,
					})
					.then((res) => {
						if (res.status) {
							this.imgUrl = res.data.url;
							this.$emit('input', res.data.url)
						} else {
							this.$message.error("图片上传出错了，请重新上传");
						}
					})
					.finally(() => {
						this.loading = false;
					});
			});
			return true;
		},
	},
};
</script>
<style lang="less" scoped>
.image-upload {
	display: flex;
	height: 142px;
	overflow: hidden;
}
// 外框
.image-wrap {
	border: 1px dashed #999;
	background-color: #fafafa;
	border-radius: 3px;
	width: 248px;
	height: 140px;
	text-align: center;
	cursor: pointer;
	color: #999;
	overflow: hidden;
	position: relative;
	&:hover {
		border-color: @color-default;
	}
}
// 上传前
.image-before{
	position: relative;
	&__img{
		display: block;
		width: 60px;
		margin: 30px auto 0;
	}
}
// 上传成功后
.image-after{
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	&__img{
		display: block;
		margin: 0 auto;
		max-width: 100%;
		max-height: 100%;
	}
	&__mask{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, .6);
		display: none;
		.mask-btn {
			display: block;
			margin: 50px auto 0;
			width: 90px;
		}
		.mask-loading{
			font-size: 30px;
		}
	}
	&:hover &__mask {
		display: block;
	}
	&__loading{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, .6);
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 40px;
		color: #fff;
	}
}
// 上传视频说明slot
.image-extra {
	margin: auto 0 2px 20px;
	line-height: 26px;
	font-size: 14px;
	color: #999;
	p {
		margin-bottom: 0;
	}
}
</style>
